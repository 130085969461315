import DeploymentService from './DeploymentService';
import { bus } from '@/main';
import { Notification } from 'vue-notifyjs';
import { of } from 'rxjs';
import { map, switchMap, finalize } from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';
import i18n from '@/i18n';
import CommonService from '@/pages/commonService';
import OrganizationService from '@/pages/organizations/OrganizationService';

const initialInterval = 30 * 1000;
const maxRetries = 7;
const backoffDelay = (retry, initialDelay) => Math.floor(Math.pow(2, retry - 1) * initialDelay);

function getStatus(deploymentId) {
  // let retries = 0
  return of(deploymentId).pipe(
    switchMap(id => DeploymentService.get(id)),
    // tap(d => console.debug(`Tentative ${++retries}/${maxRetries}: statut = ${d.status}`)),
    map((d) => {
      const status = d && d.status;
      switch (status) {
        case 'RUNNING':
          return status;
        case 'STOPPED':
        case 'ERROR':
          throw { previous: status, shouldRetry: false };
        default:
          throw { previous: status, shouldRetry: true };
      }
    })
  );
}

class DeploymentStatusService {
  constructor() {
    // ID déploiements en cours
    this.pending = new Map();
  }

  onStatusReceived(deployment, status) {
    const { id, name } = deployment;
    bus.emit('deployment-status-received', { id, status });

    Notification.notify({
      message: i18n.t('notifications.deploymentDone', { name: name }),
      type: 'info',
    });
  }

  onStatusFailed(err, deployment) {
    const { id, name } = deployment;
    const status = err.previous;
    bus.emit('deployment-status-received', { id, status });

    Notification.notify({
      message: i18n.t('notifications.deploymentFailed', { name: name }),
      type: 'danger',
    });
  }

  checkDeployments(deployments) {
    for (const deployment of deployments) {
      if (deployment.status === 'RUNNING') this.cancelTracking(deployment.id);

      if (deployment.status === 'STARTING' && !this.pending.has(deployment.id)) this.createCheckingTask(deployment);
    }
  }

  createCheckingTask(deployment) {
    const subscription = getStatus(deployment.id)
      .pipe(
        retryBackoff({
          initialInterval,
          maxRetries: maxRetries - 1,
          shouldRetry: (err) => err.shouldRetry,
          backoffDelay,
        }),
        finalize(() => this.pending.delete(deployment.id))
      )
      .subscribe(
        (status) => this.onStatusReceived(deployment, status),
        (err) => this.onStatusFailed(err, deployment)
      );
    this.pending.set(deployment.id, subscription);
  }

  cancelTracking(id) {
    if (this.pending.has(id)) {
      // console.debug(`Le suivi du déploiement ${id} a été annulé`)
      this.pending.get(id).unsubscribe();
      this.pending.delete(id);
    }
  }
}

export default new DeploymentStatusService();
